import React from 'react'

const IndexPage = (first) => { return (
    <main>
      <h1>Welcome to part 2</h1>
      <p>Attempting to discover how difficult it is to deploy a site to Amplify</p>
    </main>
)}
// const IndexPage = () => {
//     return (
//         <main>
//             <h1>Welcome to part 2</h1>
//             <p>Attempting to discover how difficult it is to deploy a site to Amplify</p>
//         </main>
//     )
// }
export default IndexPage